import React from 'react';

const AboutPage = () => {
    const jumbotronStyle = {
        backgroundImage: "url('images/me-at-grad-lowres.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center 30%',
        backgroundBlendMode: 'overlay',
        backgroundColor: 'rgba(255, 255, 255, 0.75)' // White color with 50% opacity
      };

    return (
        <div>
            {/* Header Section */}
            <div style={jumbotronStyle} className="p-5 text-center rounded-0">
                <h1 className="display-4 pt-4">Aarjav Jain</h1>
                <p className="lead py-4">BSc Computer Science</p>
            </div>

            {/* Education Section */}
            <div className="bg-dark text-white p-5 rounded-0 text-center">
            <h2>Education</h2>
            <div className="row">
                {/* KCL */}
                <div className="col">
                <h5>KCL</h5>
                <div className="py-2 d-flex justify-content-center">
                    <img src="/images/kcl-logo.jpg" alt="KCL" className="rounded-circle" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                </div>
                <p>London, UK</p>
                <p>Computer Science with Management</p>
                </div>

                {/* UofT */}
                <div className="col">
                <h5>UofT</h5>
                <div className="py-2 d-flex justify-content-center">
                    <img src="/images/uoft-logo.jpg" alt="UofT" className="rounded-circle" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                </div>
                <p>Toronto, Canada</p>
                <p>AI and Biotechnology</p>
                </div>
            </div>
            </div>

            {/* Work Experience Section */}
            <div className="bg-light text-dark p-5 rounded-0 text-center">
            <h2>Work Experience</h2>
            <div className="row">
                {/* Creatorsphere */}
                <div className="col">
                {/* Placeholder for Creatorsphere logo */}
                <div className="py-4 d-flex justify-content-center">
                    <img src="/images/creatorsphere-logo.jpg" alt="Creatorsphere" className="rounded-circle" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                </div>
                <h5>Creatorsphere</h5>
                {/* <p>Startup</p> */}
                </div>

                {/* Barclays */}
                <div className="col">
                {/* Placeholder for Barclays logo */}
                <div className="py-4 d-flex justify-content-center">
                    <img src="/images/barclays-logo.jpg" alt="Barclays" className="rounded-circle" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                </div>
                <h5>Barclays</h5>
                {/* <p>Large Corporation</p> */}
                </div>

                {/* Deutsche Bank */}
                <div className="col">
                {/* Placeholder for Deutsche Bank logo */}
                <div className="py-4 d-flex justify-content-center">
                    <img src="/images/deutsche-bank-logo.jpg" alt="Deutsche Bank" className="rounded-circle" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                </div>
                <h5>Deutsche Bank</h5>
                {/* <p>Large Corporation</p> */}
                </div>
            </div>
            </div>

            {/* Learn More Section */}
            <div className="bg-dark text-white p-5 rounded-0 text-center">
            <h2>Learn More</h2>
            <p>For more details about my professional background, please visit my LinkedIn profile.</p>
            <a href="https://www.linkedin.com/in/aarjav-jain/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                Visit My LinkedIn
            </a>
            </div>
        </div>
    );
    };

export default AboutPage;