import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-danger text-white p-4 text-center">
      <div className="container">
        <div className="row">
          <div className="col">
            <p className="mb-0">&copy; {new Date().getFullYear()} <span className='text-dark'>spyders</span>. All rights reserved.</p>
          </div>
          <div className="col">
            <a href="/about" className="text-white">About Us</a>
          </div>
          <div className="col">
            <a href="/contact" className="text-white">Contact</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
