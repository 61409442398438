import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';  // Assuming you place the CSS in the same folder and name it HomePage.css

const Navbar = () => {
  const [scrollY, setScrollY] = useState(0);
  const spiderHeight = 24;  // Approximate height of the spider icon in pixels

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScrollPosition = documentHeight - windowHeight;
      const maxSpiderPosition = windowHeight - spiderHeight - 200;

      // Calculate the rate at which the spider should move down.
      const rate = scrollPosition / maxScrollPosition;

      // Calculate the new position for the spider.
      const newPosition = rate * maxSpiderPosition;

      setScrollY(newPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return (
      <div className="nav-and-spider-container">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top p-3">
          <div className="container-fluid" style={{ zIndex: 1030 }}>
              <Link className="navbar-brand text-danger" to="/">spyders</Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="fas fa-spider p-1"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                  <li className="nav-item">
                  <Link className="nav-link text-white" to="/about">About</Link>
                  </li>
                  <li className="nav-item">
                  <Link className="nav-link text-white" to="/contact">Contact</Link>
                  </li>
              </ul>
              </div>
          </div>
      </nav>
      <div id="spider-web-container" style={{ height: `${scrollY}px` }}>
        <div id="spider-web-line" style={{ height: `${scrollY}px` }}></div>
        <i id="spider-icon" className="fas fa-spider" style={{ top: `${scrollY}px` }}></i>
      </div>
    </div>
    );
};

export default Navbar;
