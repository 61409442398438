import React from 'react';

const ContactPage = () => {
  // Create stars for contact div
  const createStars = () => {
    const contactSection = document.querySelector('.bg-light.p-5.rounded-0');
    const starSpacing = 40; // Smaller spacing for smaller stars

    // Remove old stars
    document.querySelectorAll('.star').forEach((e) => e.remove());
    for (let y = 0; y < window.innerHeight; y += starSpacing) {
      for (let x = 0; x < window.innerWidth; x += starSpacing) {
        // Make every other row horizontally offset by half the spacing
        const star = document.createElement('div');
        star.className = 'star';
        if (Math.random() < 0.04) {
          star.classList.add('star--danger');
        }
        star.style.top = `${y}px`;
        star.style.left = `${x + (y % (starSpacing * 2) === 0 ? starSpacing / 2 : 0)}px`;
        star.style.animationDelay = `${x / 400}s`;
        contactSection.appendChild(star);
      }
    }

  };

  // Create stars on page load
  React.useEffect(() => {
    createStars();
    window.addEventListener('resize', createStars);

    // Cleanup
    return () => {
      window.removeEventListener('resize', createStars);
    };
  }, []);

  return (
    <div>
      <div className="bg-light p-5 rounded-0">
        <h1 className="display-4">Contact Us</h1>
        <p className="lead">We'd love to hear from you!</p>
      </div>

      {/* Div for informing about page. i.e. if you are interested or looking for web solution, you can contact us here...*/}
      <div className="p-5">
        <h2>Interested in our services?</h2>
        <p>If you are interested in our services, please contact us using the information below.</p>
        <div className="contact-icons">
          {/* scroll down to email div */}
          <a href="#contact-email" className="text-muted me-3">
            <i className="fas fa-envelope fa-2x"></i>
          </a>
          <a href="#contact-phone" className="text-muted me-3">
            <i className="fas fa-phone-alt fa-2x"></i>
          </a>
          <a href="#contact-linkedin" className="text-muted me-3">
            <i className="fab fa-linkedin-in fa-2x"></i>
          </a>
        </div>
      </div>


      {/* Div for email information */}
      <div className="p-5" id="contact-email">
        <h2>Email</h2>
        <p>For email inquiries, please email us at <a className='text-danger' href="mailto:team.spyders.web@gmail.com">team.spyders.web@gmail.com</a>.</p>
      </div>

      {/* Div for phone number */}
      <div className="p-5" id="contact-phone">
        <h2>Phone Number</h2>
        <p>For phone inquiries, please call us at <a className='text-danger' href="tel:+44-77170-82728">+44-77170-82728</a>.</p>
      </div>

      {/* Div for LinkedIn */}
      <div className="p-5" id="contact-linkedin">
        <h2>LinkedIn</h2>
        <p>For inquiries through LinkedIn, please visit our team's LinkedIn page <a className='text-danger' href="https://www.linkedin.com/in/aarjav-jain">here</a>.</p>
      </div>
    </div>
  );
};

export default ContactPage;
