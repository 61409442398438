import React, { useEffect, useState } from 'react';
import './HomePage.css'

const HomePage = () => {
  const createStars = () => {
    const heroSection = document.querySelector('.bg-light.p-5.rounded-0');
    const starSpacing = 40; // Smaller spacing for smaller stars

    // Remove old stars
    document.querySelectorAll('.star').forEach((e) => e.remove());
    for (let y = 0; y < window.innerHeight; y += starSpacing) {
      for (let x = 0; x < window.innerWidth; x += starSpacing) {
        // Make every other row horizontally offset by half the spacing
        const star = document.createElement('div');
        star.className = 'star';
        if (Math.random() < 0.04) {
          star.classList.add('star--danger');
        }
        star.style.top = `${y}px`;
        star.style.left = `${x + (y % (starSpacing * 2) === 0 ? starSpacing / 2 : 0)}px`;
        star.style.animationDelay = `${x / 400}s`;
        heroSection.appendChild(star);
      }
    }

  };

  useEffect(() => {
    createStars();
    window.addEventListener('resize', createStars);

    // Cleanup
    return () => {
      window.removeEventListener('resize', createStars);
    };
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <div className="bg-light p-5 rounded-0">
        <h1 className="display-4">Welcome to <span className="text-danger">spyders</span></h1>
        <p className="lead">Your One-Stop Solution for Web Development</p>
        <a 
          href="#services-section" 
          className="btn btn-light mt-3" 
          onClick={(e) => {
            e.preventDefault(); 
            const element = document.getElementById('services-section');
            const offset = 50;  // Height of the navbar
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }}
        >
          Learn More <i className="fas fa-spider" style={{fontSize: "16px"}}></i>
        </a>

      </div>


      {/* Services Overview */}
      <div id="services-section" className="p-5 text-center">
        <h2>Our Services</h2>
        <div className="py-5 row">
          <div className="col-md-4">
            <i className="text-danger fas fa-code fa-3x pb-4"></i>
            <h3>Web Development</h3>
            <p>Building responsive and performant websites.</p>
          </div>
          <div className="col-md-4 position-relative">
            <i className="text-danger fas fa-mobile-alt fa-3x pb-4"></i>
            <h3>App Development</h3>
            <p>Creating user-friendly mobile applications.</p>
            <div className="coming-soon">Coming Soon</div>
          </div>
          <div className="col-md-4 position-relative">
            <i className="text-danger fas fa-chart-line fa-3x pb-4"></i>
            <h3>SEO Services</h3>
            <p>Optimizing your web presence.</p>
            <div className="coming-soon">Coming Soon</div>
          </div>
        </div>
      </div>

      {/* Featured Projects */}
      <div className="text-center bg-light py-5">
        <h2>Featured Projects</h2>
        {/* Project cards go here */}
      </div>

      {/* Testimonials */}
      <div className="text-center py-5">
        <h2>What Our Clients Say</h2>
        <blockquote>
          <p>"Spyders transformed our website and helped us improve our online presence."</p>
          <footer>- Client A</footer>
        </blockquote>
      </div>

      {/* Call to Action */}
      <div className="text-center bg-light py-5">
        <h2>Ready to Transform Your Online Presence?</h2>
        <a href="contact" className="btn btn-danger">Contact Us</a>
      </div>
    </div>
  );
};

export default HomePage;
